import React from 'react'
import { FaSpinner } from 'react-icons/fa'

export default function Loading () {
  return (
    <div className='bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317] overflow-hidden w-screen h-screen text-white flex flex-col justify-center items-center'>
      <FaSpinner className='spinner text-3xl mb-5' />

      <h1 className='text-2xl font-bold mb-1'>Loading...</h1>
    </div>
  )
}
