import React, { useEffect, useState } from 'react'

import Seo from '../components/seo'
import LoadingComponent from '../components/loading'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return <LoadingComponent />
  }

  return (
    <div className='bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317] overflow-hidden w-screen h-screen text-white flex flex-col justify-center items-center'>
      <StaticImage
        src='../images/404.png'
        alt=''
        placeholder='blurred'
        layout='fixed'
        width={300}
        className='mb-10 floatAnimation'
      />

      <h1 className='text-2xl font-bold mb-1'>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to='/'>
        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5'>
          Go Home
        </button>
      </Link>
    </div>
  )
}

export const Head = () => <Seo title='404: Not Found' />

export default NotFoundPage
